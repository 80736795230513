<script setup>
import { ref } from 'vue'
import ArtistTracks from './artists/ArtistTracks.vue'
import ArtistList from './artists/ArtistList.vue'

const selectedArtist = ref(null)
</script>

<template>
  <ArtistList v-if="!selectedArtist" @select="selectedArtist = $event" />
  <ArtistTracks v-else :artist="selectedArtist" @back="selectedArtist = null" />
</template>
