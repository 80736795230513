<script setup>
import { ref } from 'vue'
import { useVotesStore } from '../../stores/votes'
import TrackVotes from '../votes/TrackVotes.vue'
import ChevronUpIcon from '../../assets/icons/chevron_up.svg'
import appMiniPlayerOffset from '../../appMiniPlayerOffset'

const votes = useVotesStore()

const collapsed = ref(true)
</script>

<template>
  <div
    class="fixed md:static bottom-16 md:h-auto w-full z-50 px-4 md:px-0 transition-all duration-500"
    :class="[collapsed ? 'translate-y-[27rem] md:translate-y-0' : '', appMiniPlayerOffset]"
  >
    <Transition name="bump-up" mode="out-in">
      <div :key="votes.count" class="h-full rounded-t-2xl md:rounded-none overflow-hidden shadow-t-lg md:shadow-md">
        <div
          class="bg-primary w-full py-2 px-4 h-12 md:h-14 space-x-2 flex justify-between items-center z-10 selection-counter-animation-end"
          @click="collapsed = !collapsed"
        >
          <Transition name="bump" mode="out-in">
            <div
              :key="votes.count"
              class="bg-white rounded-lg px-2 text-sm inline-flex items-center font-bold text-primary"
            >
              <span class="text-lg flex">
                {{ votes.count }}
              </span>
              <span class="uppercase ml-1">tracks</span>
            </div>
          </Transition>
          <div class="flex-1 uppercase text-sm font-semibold text-white tracking-wide leading-none">
            <span v-if="!votes.minVotesSatisfied">Kies jouw favorieten</span>
            <span v-else-if="votes.maxVotesReached">Maximum tracks bereikt</span>
            <span v-else>Kies eventueel extra tracks</span>
          </div>
          <div class="text-white md:hidden">
            <ChevronUpIcon class="w-6 transition-all duration-700" :class="collapsed ? '' : 'rotate-180'" />
          </div>
        </div>
        <div class="bg-element w-full h-[28rem] p-4 overflow-auto md:hidden">
          <TrackVotes />
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.bump-enter-active,
.bump-leave-active {
  transition: all 0.1s ease-in;
}

.bump-enter-from,
.bump-leave-to {
  transform: scale(1.1);
}

.bump-up-enter-active,
.bump-up-leave-active {
  transition: all 0.2s ease-in-out;
}

.bump-up-enter-from,
.bump-up-leave-to {
  @apply -translate-y-4 md:translate-y-0;
}
</style>
