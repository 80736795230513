import { dataLayer } from '@dpgradio/creative'
import { useChartStore } from './stores/chart'

class DataLayer {
  event(action, label, options = {}) {
    const chart = useChartStore()

    dataLayer.pushEvent('chart', {
      eventAction: action,
      eventLabel: label,
      listId: chart.listId,
      editionId: chart.editionId,
      listName: chart.listName,
      editionName: chart.editionName,
      ...options,
    })
  }
}

export default new DataLayer()
