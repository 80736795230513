<script setup>
import { ref } from 'vue'
import { useSearchStore } from '../../../stores/search'
import TrackOption from '../../tracks/TrackOption.vue'
import ScrollBox from '../../elements/ScrollBox.vue'
import ChevronLeftIcon from '../../../assets/icons/chevron_left.svg'
import LoadingIndicator from '../../elements/LoadingIndicator.vue'
import CustomTrackModalButton from '../CustomTrackModalButton.vue'
import Artist from '../artists/Artist.vue'
import ArtistTracks from '../artists/ArtistTracks.vue'

const search = useSearchStore()

const selectedArtist = ref(null)
</script>

<template>
  <ArtistTracks v-if="selectedArtist" :artist="selectedArtist" @back="selectedArtist = null" />
  <div v-else>
    <button class="flex items-center mb-4 text-base font-sans" @click="search.clear">
      <ChevronLeftIcon class="h-6" />
      <span>Terug</span>
    </button>
    <ScrollBox class="space-y-4">
      <div v-if="search.count === null">
        <LoadingIndicator />
      </div>
      <div v-else-if="search.count > 0">
        <div v-if="search.artists?.length" class="w-full overflow-y-auto flex mb-4">
          <div v-for="artist in search.artists" :key="artist.id">
            <Artist :name="artist.name" :image="artist.image" class="w-26" @click="selectedArtist = artist" />
          </div>
        </div>
        <div v-if="search.tracks?.length" class="divide-y divide-solid divide-element-dark px-4 py-2 bg-element">
          <TrackOption v-for="track in search.tracks" :key="track.id" :track="track" />
        </div>
        <div class="text-center py-12 space-y-2">
          <span>Staat je favoriete track er niet bij?</span>
          <CustomTrackModalButton @close="search.clear" />
        </div>
      </div>
      <div v-else class="text-center py-12 space-y-2">
        <span>Er zijn geen resultaten gevonden. Staat je favoriete track er niet bij?</span>
        <CustomTrackModalButton @close="search.clear" />
      </div>
    </ScrollBox>
  </div>
</template>
