<script setup>
import { useWizardStore } from '../stores/wizard'
import PrimaryButton from './elements/PrimaryButton.vue'
import ChevronLeftIcon from '../assets/icons/chevron_left.svg'
import ChevronRightIcon from '../assets/icons/chevron_right.svg'
import { useSlots } from 'vue'
import appMiniPlayerOffset from '../appMiniPlayerOffset'

const wizard = useWizardStore()

const slots = useSlots()

const hasSidebar = !!slots.sidebar
</script>

<template>
  <div class="relative font-sans">
    <div class="t-container md:px-28 mx-auto lg:flex">
      <div class="pb-80 md:pb-0" :class="hasSidebar ? 'lg:w-2/3' : 'lg:w-full'">
        <slot />
      </div>
      <div v-if="hasSidebar" class="hide lg:block pl-4 flex-1">
        <div class="bg-element p-4">
          <slot name="sidebar" />
        </div>
      </div>
    </div>
    <div class="fixed md:relative bottom-0 t-container mx-auto md:px-28 z-50" :class="appMiniPlayerOffset">
      <div class="bg-primary w-full h-20 px-4 py-2 shadow-t-xl">
        <slot name="bar">
          <div class="h-full flex items-center justify-between">
            <div>
              <button
                v-if="wizard.previousButtonAvailable"
                class="flex items-center text-white text-base font-sans"
                @click="wizard.back"
              >
                <ChevronLeftIcon class="h-6" />
                Vorige
              </button>
            </div>
            <div>
              <PrimaryButton
                v-if="wizard.nextButtonAvailable"
                :disabled="wizard.nextDisabled"
                type="white"
                class="flex items-center shadow-md pr-4"
                @click="wizard.next"
              >
                Volgende
                <ChevronRightIcon class="h-6" />
              </PrimaryButton>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>
