<script setup>
import { useWizardStore } from '../stores/wizard'
import { useVotesStore } from '../stores/votes'
import { useUserStore } from '../stores/user'
import Title from '../components/elements/Title.vue'
import PrimaryButton from '../components/elements/PrimaryButton.vue'

const wizard = useWizardStore()
const votes = useVotesStore()
const user = useUserStore()

votes.$subscribe(() => {
  if (votes.submissionStatus == 'success') {
    wizard.next()
  }
})

const submit = () => {
  user.savePhoneNumber()
  votes.submit()
}
</script>

<template>
  <div>
    <div v-if="votes.submissionStatus == 'error'" class="bg-red-500 text-white p-4 mb-4">
      <Title>Oeps, er is een fout opgetreden</Title>
      <p>
        Je lijst kon helaas niet worden verstuurd. Probeer het opnieuw of neem contact met ons op met de volgende error:
      </p>
      <p class="text-xs">{{ votes.submissionErrorMessage }}</p>
    </div>

    <div>
      <PrimaryButton v-if="['idle', 'error'].includes(votes.submissionStatus)" @click="submit">Verstuur</PrimaryButton>
      <span v-if="votes.submissionStatus == 'in-progress'">Laden...</span>
    </div>
  </div>
</template>
