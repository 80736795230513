<script setup>
import { watch } from 'vue'
import { useSearchStore } from '../../../stores/search'
import TextInput from '../../elements/TextInput.vue'
import SearchIcon from '../../../assets/icons/search.svg'

const search = useSearchStore()

watch(
  () => search.query,
  () => {
    if (search.query) {
      search.search()
    } else {
      search.clear()
    }
  }
)
</script>

<template>
  <div class="relative">
    <SearchIcon
      class="pointer-events-none w-7 h-7 absolute top-1/2 transform -translate-y-1/2 left-4 z-20 text-gray-700"
    />
    <TextInput
      v-model="search.query"
      type="search"
      label="Zoek je favoriete track of artiest"
      inputClass="!pl-12"
      labelClass="!left-12"
    />
  </div>
</template>
