<script setup>
import { Shareable, ImageGeneratorProperties } from '@dpgradio/creative/share'
import { config } from '@dpgradio/creative'
import params from '../../params'
import { useChartStore } from '../../stores/chart'
import { useVotesStore } from '../../stores/votes'
import Subtitle from '../elements/Subtitle.vue'
import ShareButton from './ShareButton.vue'
import FacebookIcon from '../../assets/icons/facebook.svg'
import InstagramIcon from '../../assets/icons/instagram.svg'
import WhatsappIcon from '../../assets/icons/whatsapp.svg'

const chart = useChartStore()
const votes = useVotesStore()

const shareUrl = params.share_url || window.XhrLoader?.lastState?.url

const shareable = new Shareable()
  .withTitle(`Dit is mijn top-${chart.constraints.minVotes} voor de ${chart.name}`)
  .withDescription('Stem ook op jouw favorieten voor de lijst!')
  .withMessageText(
    `Dit is mijn top-${chart.constraints.minVotes} voor de ${chart.name}. Stem ook op jouw favorieten voor de lijst!`
  )
  .redirectTo(shareUrl)
  .fromDomain(config('domain'))

const createImageProperties = (width, height, platform) =>
  new ImageGeneratorProperties(
    `https://static.radio.dpgmedia.net/chart-share/index.html?platform=${platform}&theme=${config('app.theme')}`
  )
    .withDimensions(width, height)
    .withPayload({
      backgroundImage: chart.shareImages[platform],
      tracks: votes.topVotes.map(([, track]) => track),
    })

const shareOnFacebook = async () => {
  const image = createImageProperties(1200, 630, 'facebook')

  ;(await shareable.generateUsingImage(image)).openFacebookUrl()
}

const shareOnInstagram = async () => {
  const image = createImageProperties(1080, 1920, 'instagram')

  ;(await shareable.generateUsingImage(image)).openInstagramUrl()
}

const shareOnWhatsapp = async () => {
  const image = createImageProperties(1200, 630, 'facebook')

  ;(await shareable.generateUsingImage(image)).openWhatsappUrl()
}

const available = (platform) => chart.shareImages[platform]
</script>

<template>
  <div class="space-y-2">
    <Subtitle v-if="available('facebook') || available('instagram')">Deel je lijstje</Subtitle>
    <div>
      <ShareButton v-if="available('facebook')" :share="shareOnFacebook">
        <FacebookIcon class="w-6 h-6 fill-current" />
      </ShareButton>
      <ShareButton v-if="available('instagram')" :share="shareOnInstagram">
        <InstagramIcon class="w-6 h-6 fill-current" />
      </ShareButton>
      <ShareButton v-if="available('facebook')" :share="shareOnWhatsapp">
        <WhatsappIcon class="w-6 h-6 fill-current" />
      </ShareButton>
    </div>
  </div>
</template>
