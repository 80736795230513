<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import params from './params'
import { useChartStore } from './stores/chart'
import { useUserStore } from './stores/user'
import { useWizardStore } from './stores/wizard'
import { hybrid, config, configuration } from '@dpgradio/creative'
import RestoreModal from './components/RestoreModal.vue'
import LoadingIndicator from './components/elements/LoadingIndicator.vue'
import PrimaryButton from './components/elements/PrimaryButton.vue'

const breakpoints = useBreakpoints(breakpointsTailwind)
const mdAndLarger = breakpoints.greater('md')

const chart = useChartStore()
chart.fetchChartDetails()

const user = useUserStore()
user.setup()

const wizard = useWizardStore()

const active = ref(params.platform == 'app_hybrid' || wizard.openInterfaceAfterRestore)

document.querySelector('body')?.classList.add(config('app.theme'))

const openVoting = () => {
  if (hybrid.isNativeApp()) {
    const queryString = new URLSearchParams({
      platform: 'app_hybrid',
      list_id: chart.listId,
      edition_id: chart.editionId,
      station: configuration.stationId,
      share_url: window.XhrLoader?.lastState?.url || '',
    }).toString()
    const nativeAppUrl = `https://charts-voting.radio.dpgmedia.net/?${queryString}`
    hybrid.openUrl(nativeAppUrl, { mode: 'seque' })
  } else {
    active.value = true
  }
}
</script>

<template>
  <div :class="config('app.theme')">
    <div class="charts-reset">
      <div v-if="mdAndLarger">
        <LoadingIndicator v-if="!chart.listName" />
        <component :is="wizard.current.component" v-else />
        <RestoreModal class="hide md:block" />
      </div>
      <div v-else>
        <LoadingIndicator v-if="!chart.listName" />
        <PrimaryButton v-else class="mx-2" @click="openVoting">Stem nu voor de {{ chart.name }}</PrimaryButton>
        <Dialog :class="config('app.theme')" :open="active">
          <div class="relative z-50 charts-reset">
            <div class="fixed inset-0 bg-white optional-theme-offset">
              <DialogPanel class="w-full h-screen overflow-auto mobile-dialog">
                <LoadingIndicator v-if="!chart.listName" />
                <component :is="wizard.current.component" v-else />
              </DialogPanel>
            </div>
          </div>
        </Dialog>
        <RestoreModal v-if="active" class="md:hidden" />
      </div>
    </div>
  </div>
</template>
