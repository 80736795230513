<script setup>
import { ref } from 'vue'
import { useChartStore } from '../../stores/chart'
import TrackOption from '../tracks/TrackOption.vue'
import Subtitle from '../elements/Subtitle.vue'
import ReloadIcon from '../../assets/icons/reload.svg'
import PrimaryButton from '../elements/PrimaryButton.vue'

const chart = useChartStore()

const loading = ref(false)
const refresh = async () => {
  loading.value = true
  await chart.fetchRandomTracks()
  loading.value = false
}
</script>

<template>
  <div v-if="chart.trackSuggestions.length" class="space-y-4">
    <div class="flex justify-between items-center space-x-4">
      <Subtitle>Is één van deze 5 tracks iets voor jou?</Subtitle>
      <PrimaryButton @click="refresh">
        <ReloadIcon class="h-7" :class="loading && 'animate-spin'" />
      </PrimaryButton>
    </div>
    <div class="divide-y divide-solid divide-element-dark px-4 py-2 bg-element">
      <TrackOption v-for="track in chart.trackSuggestions" :key="track.id" :track="track" />
    </div>
  </div>
</template>
