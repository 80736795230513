import { defineStore } from 'pinia'
import api from '../api'
import dataLayer from '../dataLayer'
import { mapRawArtists, mapRawTracks } from '../mapping'
import { useChartStore } from './chart'

export const useSearchStore = defineStore('search', {
  state: () => {
    return {
      query: '',
      tracks: null,
      artists: null,
      debounceTimeout: undefined,
    }
  },
  getters: {
    count() {
      if (this.tracks == null && this.artists == null) {
        return null
      }
      return this.tracks?.length + this.artists?.length
    },
  },
  actions: {
    async search() {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(async () => {
        const chart = useChartStore()

        const [tracksResponse, artistsResponse] = await Promise.all([
          api.searchTracks(chart.listId, chart.editionId, this.query),
          api.artists(chart.listId, chart.editionId, { search: this.query }),
        ])

        this.tracks = mapRawTracks(tracksResponse.tracks)
        this.artists = mapRawArtists(artistsResponse.artists)

        dataLayer.event('selection', 'search')
      }, 200)
    },
    clear() {
      this.query = ''
      this.tracks = null
      this.artists = null
    },
  },
})
