<script setup>
import { Dialog, DialogPanel } from '@headlessui/vue'
import { config } from '@dpgradio/creative'

defineProps({
  open: { type: Boolean, default: false },
})

const emit = defineEmits(['close'])

const close = () => {
  emit('close')
}
</script>

<template>
  <Dialog :class="config('app.theme')" :open="open" @close="close">
    <div class="relative z-50 charts-reset font-sans">
      <div class="fixed inset-0 flex items-center justify-center bg-gray-700/50">
        <DialogPanel class="w-full max-w-sm bg-white shadow-xl rounded-lg p-4 space-y-4">
          <slot />
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>
