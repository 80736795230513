<script setup>
import { useWizardStore } from '../stores/wizard'
import { useChartStore } from '../stores/chart'
import { useVotesStore } from '../stores/votes'
import Layout from '../components/Layout.vue'
import Title from '../components/elements/Title.vue'
import ContentBox from '../components/elements/ContentBox.vue'
import Track from '../components/tracks/Track.vue'
import TrackPosition from '../components/votes/TrackPosition.vue'
import Ranking from '../components/share/Ranking.vue'
import ThankYou from '../components/share/ThankYou.vue'

const wizard = useWizardStore()
const chart = useChartStore()
const votes = useVotesStore()

const reset = () => {
  wizard.$reset()
  votes.$reset()
}
</script>

<template>
  <Layout>
    <template #sidebar>
      <ThankYou />
    </template>
    <ContentBox class="md:hidden">
      <ThankYou />
    </ContentBox>
    <ContentBox class="!bg-element space-y-2">
      <div class="space-y-2">
        <Title class="text-center">Jouw Top {{ chart.constraints.minVotes }}</Title>
        <Ranking :tracks="votes.topVotes" />
        <div class="divide-y divide-solid divide-element-dark space-y-1">
          <div
            v-for="[position, track] in votes.topVotes"
            :key="track.id"
            class="flex relative bg-accent text-accent-c shadow-md pl-3"
          >
            <TrackPosition :position="position" />
            <Track :track="track" />
          </div>
        </div>
      </div>
      <div v-if="votes.extraVotes.length" class="space-y-2">
        <div class="divide-y divide-solid divide-element-dark space-y-2">
          <Track v-for="[, track] in votes.extraVotes" :key="track.id" :track="track" />
        </div>
      </div>
    </ContentBox>
    <template #bar>
      <div class="w-12 h-12" @click="reset" />
    </template>
  </Layout>
</template>
