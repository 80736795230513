<script setup>
import { ref } from 'vue'
import CustomTrackModal from './CustomTrackModal.vue'
import PrimaryButton from '../elements/PrimaryButton.vue'

const props = defineProps({
  artist: { type: String, default: null },
})

const emit = defineEmits(['close'])

const customTrackModalActive = ref(false)

const closeCustomTrackModal = () => {
  customTrackModalActive.value = false
  emit('close')
}
</script>

<template>
  <PrimaryButton class="block mx-auto" @click="customTrackModalActive = true">Track toevoegen</PrimaryButton>
  <CustomTrackModal :open="customTrackModalActive" :artist="props.artist" @close="closeCustomTrackModal" />
</template>
