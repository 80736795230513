<script setup>
import { authentication } from '@dpgradio/creative'
import { useWizardStore } from '../stores/wizard'
import PrimaryButton from '../components/elements/PrimaryButton.vue'
import Layout from '../components/Layout.vue'
import Title from '../components/elements/Title.vue'
import Header from '../components/elements/Header.vue'

const wizard = useWizardStore()

wizard.disableNext()

const skipIfLoggedIn = () => {
  if (authentication.isLoggedIn()) {
    wizard.skip()
  }
}
skipIfLoggedIn()
authentication.onLogin(skipIfLoggedIn)
</script>

<template>
  <Layout>
    <Header class="space-y-6">
      <Title>Inloggen</Title>
      <p>
        Om je lijstje door te sturen vragen we je om je in te loggen. Heb je nog geen account? Dan kan je er één
        aanmaken!
      </p>
      <PrimaryButton @click="authentication.require()">Login of Registreer</PrimaryButton>
    </Header>
  </Layout>
</template>
