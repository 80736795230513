import { api } from '@dpgradio/creative'

class Api {
  async tracks(listId, editionId) {
    return api
      .onVersion('2.0') // The application is still using the old-style edition endpoints (before track lists were introduced).
      .request()
      .get(`/lists/${listId}/editions/${editionId}/votes/tracks`, {
        order: 'random',
        seed: parseInt(Math.random() * 500),
      })
  }

  async searchTracks(listId, editionId, query, options = { order: undefined, page: undefined }) {
    return api
      .onVersion('2.0')
      .request()
      .get(`/lists/${listId}/editions/${editionId}/votes/tracks/search`, {
        query,
        ...api.lists.withoutNullValues(options),
      })
  }

  async tracksByLetter(listId, editionId, letter, options = { page: undefined }) {
    return api
      .onVersion('2.0')
      .request()
      .get(
        `/lists/${listId}/editions/${editionId}/votes/tracks/by_letter/${encodeURIComponent(letter)}`,
        api.lists.withoutNullValues(options)
      )
  }

  async artists(listId, editionId, options = { offset: undefined }, pagination) {
    if (!pagination) {
      return api
        .onVersion('2.0')
        .request()
        .get(`/lists/${listId}/editions/${editionId}/votes/artists`, api.lists.withoutNullValues(options))
    }
    if (pagination.next) {
      return api.onVersion(null).request().get(pagination.next)
    }

    return { artists: [], pagination }
  }

  async tracksByArtist(listId, editionId, artistId) {
    return api.onVersion('2.0').request().get(`/lists/${listId}/editions/${editionId}/votes/artists/${artistId}`)
  }

  async submitVotes(
    listId,
    editionId,
    votes,
    { firstName, lastName, phoneNumber, email },
    consent = { phone: false, notification: false }
  ) {
    return api.onVersion('2.0').request().post(`/lists/${listId}/editions/${editionId}/new_site_votes`, {
      votes, // format: [{id, comment}]
      phone_consent: consent.phone,
      notification_consent: consent.notification,
      crm_consent: consent.crm,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email,
    })
  }

  async getCrmQuestion() {
    return api.request().get(`/crm_consent/question`)
  }
}

export default new Api()
