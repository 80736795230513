<script setup>
import Splash from '../../assets/icons/splash.svg'
import Crown from '../../assets/icons/crown.svg'

const props = defineProps({
  tracks: { type: Array, required: true },
})

const indexedTracks = Object.fromEntries(props.tracks)
</script>

<template>
  <div class="flex justify-center pt-6 pb-12">
    <div v-if="indexedTracks[2]" class="relative z-10 -mr-10 mt-12">
      <div class="rounded-full w-32 h-32 border-solid border-4 border-accent mx-auto overflow-hidden shadow-xl">
        <img :src="indexedTracks[2].thumbnail" class="object-cover h-full w-full" />
      </div>
      <div
        class="absolute -bottom-2 left-0 right-0 mx-auto rounded-full bg-accent text-accent-c w-8 h-8 text-xl flex items-center font-bold font-highlights justify-center z-10"
      >
        2
      </div>
    </div>

    <div class="relative">
      <Splash class="absolute z-0 top-0 left-0 right-0 -ml-16 -mt-16 w-72 opacity-25 text-accent" />
      <Crown class="absolute z-0 top-0 left-0 right-0 mx-auto -mt-9 w-10 text-accent" />
      <div
        class="rounded-full w-40 h-40 border-solid border-4 border-accent mx-auto overflow-hidden shadow-xl relative z-10"
      >
        <img :src="indexedTracks[1].thumbnail" class="object-cover h-full w-full" />
      </div>
      <div
        class="absolute bottom-2 left-0 right-0 mx-auto rounded-full bg-accent text-accent-c w-8 h-8 text-xl flex items-center font-bold font-highlights justify-center z-10"
      >
        1
      </div>
    </div>

    <div v-if="indexedTracks[3]" class="relative z-10 -ml-10 mt-12">
      <div
        class="rounded-full w-32 h-32 border-solid border-4 border-accent mx-auto overflow-hidden shadow-xl relative"
      >
        <img :src="indexedTracks[3].thumbnail" class="object-cover h-full w-full" />
      </div>
      <div
        class="absolute -bottom-2 left-0 right-0 mx-auto rounded-full bg-accent text-accent-c w-8 h-8 text-xl flex items-center font-bold font-highlights justify-center z-10"
      >
        3
      </div>
    </div>
  </div>
</template>
