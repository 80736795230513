<script setup>
import { defineEmits } from 'vue'
import { useArtistsStore } from '../../../stores/artists'
import DynamicLoader from '../../elements/DynamicLoader.vue'
import ScrollBox from '../../elements/ScrollBox.vue'
import Artist from './Artist.vue'

const emit = defineEmits(['select'])

const artists = useArtistsStore()

const loadMoreArtists = async () => await artists.fetchNextArtists()
</script>

<template>
  <ScrollBox>
    <DynamicLoader :load="loadMoreArtists">
      <div class="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-1">
        <Artist
          v-for="artist in artists.all"
          :key="artist.id"
          :name="artist.name"
          :image="artist.image"
          @click="emit('select', artist)"
        />
      </div>
    </DynamicLoader>
  </ScrollBox>
</template>
