import { defineStore } from 'pinia'
import api from '../api'
import { editionId, listId } from '../settings'
import dataLayer from '../dataLayer'
import { useChartStore } from './chart'
import { useUserStore } from './user'

const range = (start, end) => Array.from(Array(Math.max(end - start, 0)).keys()).map((i) => i + start + 1)

export const useVotesStore = defineStore('votes', {
  state: () => {
    return {
      tracks: [],
      comments: {},
      meta: {
        notificationConsent: false,
        phoneConsent: false,
      },
      submissionStatus: 'idle',
      submissionErrorMessage: undefined,
    }
  },
  getters: {
    topVotes() {
      const chart = useChartStore()

      const votes = this.tracks.slice(0, chart.constraints.minVotes).map((track, index) => [index + 1, track])
      const placeholders = range(this.tracks.length, chart.constraints.minVotes).map((i) => [i, undefined])

      return [...votes, ...placeholders]
    },
    extraVotes() {
      const chart = useChartStore()

      return this.tracks
        .slice(chart.constraints.minVotes)
        .map((track, index) => [index + chart.constraints.minVotes + 1, track])
    },
    votedOn: (state) => {
      return (track) => state.tracks.some((other) => track.id === other.id)
    },
    count() {
      return this.tracks.length
    },
    minVotesSatisfied() {
      const chart = useChartStore()

      return this.count >= chart.constraints.minVotes
    },
    maxVotesReached() {
      const chart = useChartStore()

      return this.count >= chart.constraints.maxVotes
    },
    maxVotesPerArtistReached: (state) => {
      return (artistId) => {
        const chart = useChartStore()

        if (chart.constraints.maxVotesPerArtist == 0) {
          return false
        }

        const tracksByArtist = state.tracks.filter((track) => track.artistId == artistId)

        return tracksByArtist.length == chart.constraints.maxVotesPerArtist
      }
    },
    last() {
      return this.tracks[this.tracks.length - 1]
    },
  },
  actions: {
    vote(track) {
      if (this.maxVotesReached) {
        dataLayer.event('votes', 'max_reached')
        return
      }
      if (!track.id.toString().startsWith('custom-track') && this.maxVotesPerArtistReached(track.artistId)) {
        dataLayer.event('votes', 'max_per_artist_reached')
        return
      }
      if (this.votedOn(track)) {
        return
      }
      this.tracks.push(track)
      dataLayer.event('votes', 'add')
    },
    remove(track) {
      this.tracks.splice(this.tracks.indexOf(track), 1)
      dataLayer.event('votes', 'remove')
    },
    move(track, index) {
      this.remove(track)
      this.tracks.splice(index, 0, track)
      dataLayer.event('votes', 'move')
    },
    async submit() {
      this.submissionStatus = 'in-progress'
      try {
        const tracks = this.tracks.map((track) => ({
          ...(track.id !== 0 ? { id: track.id } : { title: track.title, artist: track.artist }),
          comment: this.comments[track.id],
        }))
        await this.submitVote(tracks)
        this.submissionStatus = 'success'
        dataLayer.event('submit', 'success', {
          votesCount: this.count,
          commentsCount: Object.entries(this.comments).length,
        })
      } catch (error) {
        console.error(error)
        this.submissionStatus = 'error'
        this.submissionErrorMessage = error.message + ' ' + JSON.stringify(error.response?.data)
        dataLayer.event('submit', 'error')
      }
    },
    async submitVote(tracks) {
      const chart = useChartStore()
      const user = useUserStore()
      return await api.submitVotes(chart.listId, chart.editionId, tracks, user.custom, {
        phone: this.meta.phoneConsent,
        notification: this.meta.notificationConsent,
        crm: this.meta.crmConsent,
      })
    },
  },
  persist: {
    key: `votes_${listId}_${editionId}`,
  },
})
