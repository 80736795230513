<script setup>
import { ref } from 'vue'
import Modal from './elements/Modal.vue'
import { useWizardStore } from '../stores/wizard'
import { useVotesStore } from '../stores/votes'
import Title from './elements/Title.vue'
import SubtleLink from './elements/SubtleLink.vue'
import PrimaryButton from './elements/PrimaryButton.vue'
import ChevronRightIcon from '../assets/icons/chevron_right.svg'
import dataLayer from '../dataLayer'

const wizard = useWizardStore()
const votes = useVotesStore()

const open = ref(wizard.restored && !wizard.isLast && votes.count > 0)

const reset = () => {
  wizard.$reset()
  votes.$reset()
  close()
  dataLayer.event('restore', 'restart')
}

const close = () => {
  open.value = false
  dataLayer.event('restore', 'continue')
}
</script>

<template>
  <Modal :open="open" @close="close">
    <Title>Welkom terug!</Title>
    <p>Je was al begonnen met het samenstellen van je lijst. Wil je je lijst afmaken of opnieuw beginnen?</p>
    <div class="flex justify-between items-end">
      <SubtleLink @click="reset">Opnieuw beginnen</SubtleLink>
      <PrimaryButton class="flex items-center pr-4" @click="close">
        Verder gaan
        <ChevronRightIcon class="h-6" />
      </PrimaryButton>
    </div>
  </Modal>
</template>
