<script setup>
import { usePlayerStore } from '../../stores/player'
import PlayIcon from '../../assets/icons/play.svg'
import StopIcon from '../../assets/icons/stop.svg'
import { ref, watch } from 'vue'

const props = defineProps({
  track: { type: Object, required: true },
  selected: { type: Boolean, default: false },
})

const player = usePlayerStore()

const animatedThumbnail = ref(null)

watch(
  () => props.selected,
  () => {
    if (props.selected) {
      const destination = Array.from(document.querySelectorAll('.selection-counter-animation-end')).find(
        (element) => element.getBoundingClientRect().top > 0
      )

      if (!destination) {
        return
      }

      const destinationRect = destination.getBoundingClientRect()
      const thumbnailRect = animatedThumbnail.value.getBoundingClientRect()

      const offsetY = destinationRect.top - thumbnailRect.top
      const offsetX = destinationRect.left - thumbnailRect.left + destinationRect.width / 2 - thumbnailRect.width / 2

      animatedThumbnail.value.style.top = `${offsetY}px`
      animatedThumbnail.value.style.left = `${offsetX}px`
      animatedThumbnail.value.style.transform = 'rotate(20deg)'
      animatedThumbnail.value.style.opacity = 0
      setTimeout(() => {
        animatedThumbnail.value.display = 'none'
      }, 1000)
    } else {
      animatedThumbnail.value.display = 'block'
      animatedThumbnail.value.style.top = '0px'
      animatedThumbnail.value.style.left = '0px'
      animatedThumbnail.value.style.transform = 'rotate(0deg)'
      animatedThumbnail.value.style.opacity = 1
    }
  }
)
</script>

<template>
  <div class="flex items-center space-x-2 py-2">
    <div class="h-14 w-14 bg-gray-400 relative shadow-md">
      <img
        v-if="track.thumbnail"
        ref="animatedThumbnail"
        :src="track.thumbnail"
        class="w-full h-full opacity-50 absolute transition-all duration-1000 ease-out z-30 top-0 left-0"
      />
      <img v-if="track.thumbnail" :src="track.thumbnail" class="w-full h-full" />
      <button
        v-if="track.preview"
        class="absolute top-0 w-full h-full flex justify-center items-center text-white bg-black/10 hover:bg-transparent z-40"
        @click="player.togglePlay(track.preview)"
      >
        <StopIcon v-if="player.isPlaying(track.preview)" class="h-10" />
        <PlayIcon v-else class="h-10" />
      </button>
    </div>
    <div class="flex-1 font-highlights">
      <div class="font-bold text-lg leading-none my-1 line-clamp-1" :title="track.title">{{ track.title }}</div>
      <div class="text leading-none line-clamp-1" :title="track.artist">{{ track.artist }}</div>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>
