import { defineStore } from 'pinia'
import { editionId, isKioskMode, listId } from '../settings'
import dataLayer from '../dataLayer'
import { config } from '@dpgradio/creative'

import Selection from '../screens/Selection.vue'
import ListReview from '../screens/ListReview.vue'
import ListComments from '../screens/ListComments.vue'
import Login from '../screens/Login.vue'
import PersonalDetails from '../screens/PersonalDetails.vue'
import Consent from '../screens/Consent.vue'
import Share from '../screens/Share.vue'
import { useUserStore } from './user'

export const useWizardStore = defineStore('wizard', {
  state: () => {
    return {
      currentIndex: 0,
      oldIndex: 0,
      nextDisabled: false,
      restored: false,
      openInterfaceAfterRestore: false,
    }
  },
  getters: {
    steps() {
      const userStore = useUserStore()
      if (config('app.requires_login') === 'true' && !isKioskMode) {
        return [
          { component: Selection },
          { component: ListReview },
          { component: ListComments },
          { component: Login, userReturnsInstantly: true },
          { component: Consent, nextAvailable: false },
          { component: Share, previousAvailable: false },
        ]
      } else if (userStore.profile) {
        return [
          { component: Selection },
          { component: ListReview },
          { component: ListComments },
          { component: Consent, nextAvailable: false },
          { component: Share, previousAvailable: false },
        ]
      } else {
        return [
          { component: Selection },
          { component: ListReview },
          { component: ListComments },
          { component: PersonalDetails, nextAvailable: false },
          { component: Share, previousAvailable: false },
        ]
      }
    },
    current() {
      return this.steps[this.currentIndex]
    },
    nextIndex() {
      return this.currentIndex + 1
    },
    nextAvailable() {
      return this.nextIndex < this.steps.length
    },
    nextButtonAvailable() {
      if (this.current.nextAvailable === false) {
        return false
      }
      return this.nextAvailable
    },
    previousIndex() {
      return this.currentIndex - 1
    },
    previousAvailable() {
      return this.previousIndex < this.steps.length && this.previousIndex >= 0
    },
    previousButtonAvailable() {
      if (this.current.previousAvailable === false) {
        return false
      }
      return this.previousAvailable
    },
    comingFromNextStep() {
      return this.oldIndex > this.currentIndex
    },
    comingFromPreviousStep() {
      return this.oldIndex < this.currentIndex
    },
    isLast() {
      return this.currentIndex === this.steps.length - 1
    },
  },
  actions: {
    next() {
      if (this.nextAvailable) {
        dataLayer.event('wizard_controls', 'next')
        dataLayer.event('wizard_step', this.nextIndex)
        this.oldIndex = this.currentIndex
        this.currentIndex = this.nextIndex
        this.enableNext()
        this.resetScrollPosition()
      }
    },
    back() {
      if (this.previousAvailable) {
        dataLayer.event('wizard_controls', 'back')
        dataLayer.event('wizard_step', this.previousIndex)
        this.oldIndex = this.currentIndex
        this.currentIndex = this.previousIndex
        this.enableNext()
        this.resetScrollPosition()
      }
    },
    skip() {
      if (this.comingFromPreviousStep) {
        this.next()
      } else {
        this.back()
      }
    },
    disableNext() {
      this.nextDisabled = true
    },
    enableNext() {
      this.nextDisabled = false
    },
    resetScrollPosition() {
      document.querySelector('.charts-reset')?.scrollIntoView()
      const mobileDialog = document.querySelector('.mobile-dialog')
      if (mobileDialog) {
        mobileDialog.scrollTop = 0
      }
    },
  },
  persist: {
    key: `wizard_${listId}_${editionId}`,
    afterRestore(context) {
      context.store.openInterfaceAfterRestore = false
      context.store.restored = false
      if (context.store.current.userReturnsInstantly) {
        context.store.openInterfaceAfterRestore = true
      } else {
        context.store.restored = true
      }
    },
  },
})
