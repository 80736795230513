import { defineStore } from 'pinia'
import api from '../api'
import dataLayer from '../dataLayer'
import { mapRawArtists, mapRawTracks } from '../mapping'
import { useChartStore } from './chart'

export const useArtistsStore = defineStore('artists', {
  state: () => {
    return {
      artists: [],
      artistsSeed: parseInt(Math.random() * 500),
      tracksByArtist: {},
      pagination: null,
    }
  },
  getters: {
    all() {
      return this.artists
    },
    tracks: (state) => {
      return (artist) => state.tracksByArtist[artist.id]
    },
  },
  actions: {
    async fetchNextArtists() {
      const chart = useChartStore()

      const { artists, pagination } = await api.artists(
        chart.listId,
        chart.editionId,
        {
          order: 'random',
          seed: this.artistsSeed,
        },
        this.pagination
      )

      this.artists = [...this.artists, ...mapRawArtists(artists)]
      this.pagination = pagination

      dataLayer.event('selection', 'artists')

      return {
        totalLoaded: this.artists.length,
        totalCount: this.pagination.total,
        nextAvailable: !!this.pagination.next,
      }
    },
    async fetchTracksByArtist(artist) {
      if (this.tracksByArtist[artist.id]) {
        return
      }

      const chart = useChartStore()

      const result = await api.tracksByArtist(chart.listId, chart.editionId, artist.id)

      this.tracksByArtist[artist.id] = mapRawTracks(result.tracks)

      dataLayer.event('selection', 'artist_detail')
    },
  },
})
