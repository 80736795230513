<script setup>
import { useVotesStore } from '../../stores/votes'
import Track from './Track.vue'
import PlusIcon from '../../assets/icons/plus.svg'
import MinusIcon from '../../assets/icons/minus.svg'
import PrimaryButton from '../elements/PrimaryButton.vue'

defineProps({
  track: { type: Object, required: true },
})

const votes = useVotesStore()
</script>

<template>
  <Track :track="track" :selected="votes.votedOn(track)">
    <PrimaryButton v-if="votes.votedOn(track)" type="white" class="rounded-md !px-5" @click="votes.remove(track)">
      <MinusIcon class="w-5 h-5" />
    </PrimaryButton>
    <div v-else-if="votes.maxVotesReached" class="text-gray-500 text-xs">Maximum bereikt</div>
    <div v-else-if="votes.maxVotesPerArtistReached(track.artistId)" class="text-gray-500 text-xs leading-none">
      Maximum bereikt<br />voor deze artiest
    </div>
    <PrimaryButton v-else class="rounded-md !px-5" @click="votes.vote(track)">
      <PlusIcon class="w-5 h-5" />
    </PrimaryButton>
  </Track>
</template>
