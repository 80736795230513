<script setup>
defineProps({
  modelValue: { type: String, required: true },
  type: { type: String, default: 'text' },
  inputClass: { type: String, default: '' },
  labelClass: { type: String, default: '' },
  label: { type: String, required: true },
})
defineEmits(['update:modelValue'])

const id = Math.ceil(Math.random() * 1000000)
</script>

<template>
  <div class="relative">
    <input
      :id="id"
      :type="type"
      class="!block !px-4 !pt-5 !pb-2 !bg-element-light !rounded-xl !w-full !outline-primary peer text-base text-gray-700"
      :class="inputClass"
      placeholder=" "
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <label
      :for="id"
      class="absolute !text-gray-400 duration-300 transform -translate-y-2 scale-75 origin-top-left top-3.5 z-10 left-4 peer-focus:text-gray-800 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-2"
      :class="labelClass"
      v-text="label"
    />
  </div>
</template>
