<script setup>
import { google, outlook, office365, ics } from 'calendar-link'
import { config } from '@dpgradio/creative'
import { useChartStore } from '../../stores/chart'
import Modal from '../elements/Modal.vue'
import Title from '../elements/Title.vue'
import SubtleLink from '../elements/SubtleLink.vue'
import PrimaryButton from '../elements/PrimaryButton.vue'

defineProps({
  open: { type: Boolean, default: false },
})

const emit = defineEmits(['close'])

const chart = useChartStore()

const close = () => {
  emit('close')
}

const event = {
  title: chart.name,
  location: config('name'),
  start: chart.startDate,
  end: chart.endDate,
  busy: false,
  url: config('website_url'),
}

const calendarLinks = {
  Apple: ics(event),
  Google: google(event),
  Outlook: outlook(event),
  'Microsoft 365': office365(event),
  'iCal file': ics(event),
}
</script>

<template>
  <Modal :open="open" @close="close">
    <Title>Voeg de {{ chart.name }} toe aan je agenda</Title>
    <div class="bg-element p-4 space-y-2 -mx-4">
      <a v-for="[name, link] in Object.entries(calendarLinks)" :key="name" :href="link" class="block">
        <PrimaryButton class="block w-full shadow-md">{{ name }}</PrimaryButton>
      </a>
    </div>
    <SubtleLink class="block" @click="close">Annuleer</SubtleLink>
  </Modal>
</template>
