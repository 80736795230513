<script setup>
import { ref } from 'vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { useWizardStore } from '../../stores/wizard'
import { useChartStore } from '../../stores/chart'
import { useVotesStore } from '../../stores/votes'
import { useUserStore } from '../../stores/user'
import draggable from 'vuedraggable'
import TrackVotePlaceholder from './TrackVotePlaceholder.vue'
import TrackVote from './TrackVote.vue'
import Title from '../elements/Title.vue'
import PrimaryButton from '../elements/PrimaryButton.vue'
import ChevronLeftIcon from '../../assets/icons/chevron_left.svg'

defineProps({
  fullPage: { type: Boolean, default: false },
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const mdAndLarger = breakpoints.greater('md')

const wizard = useWizardStore()
const chart = useChartStore()
const votes = useVotesStore()
const user = useUserStore()

const moving = ref(true)

const changeOrder = (event, offset = 0) =>
  ['moved', 'added'].forEach((eventType) => {
    if (eventType in event) {
      const {
        element: [, track],
        newIndex,
      } = event[eventType]
      if (track) {
        votes.move(track, newIndex + offset)
      }
    }
  })
</script>

<template>
  <div class="space-y-6">
    <div>
      <Title v-if="user.profile">{{ user.profile.first_name }}'s Top {{ chart.constraints.minVotes }}</Title>
      <Title v-else>Jouw Persoonlijke Top {{ chart.constraints.minVotes }}</Title>
      <draggable
        :list="votes.topVotes"
        itemKey="position"
        class="mt-2 space-y-1"
        group="votes"
        :handle="mdAndLarger ? false : '.handle'"
        @change="changeOrder"
        @start="moving = true"
        @end="moving = false"
      >
        <template #item="{ element: [position, track] }">
          <TrackVote
            v-if="track"
            :position="position"
            :showPosition="true"
            :track="track"
            :class="moving ? 'cursor-grabbing' : 'cursor-grab'"
            class="bg-accent text-accent-c shadow-md"
          />
          <TrackVotePlaceholder v-else :position="position" />
        </template>
      </draggable>
    </div>

    <div>
      <Title>Extra stemmen</Title>
      <draggable
        v-if="votes.extraVotes.length"
        :list="votes.extraVotes"
        itemKey="position"
        class="mt-2 space-y-2 divide-solid divide-y divide-element-dark py-2 bg-element"
        group="votes"
        :handle="mdAndLarger ? false : '.handle'"
        @change="changeOrder($event, chart.constraints.minVotes)"
        @start="moving = true"
        @end="moving = false"
      >
        <template #item="{ element: [position, track] }">
          <TrackVote :position="position" :track="track" :class="moving ? 'cursor-grabbing' : 'cursor-grab'" />
        </template>
      </draggable>
      <div v-else>
        <p class="!mb-2">
          Naast je top {{ chart.constraints.minVotes }} kun je nog tot
          {{ chart.constraints.maxVotes - chart.constraints.minVotes }} extra tracks kiezen om je lijst compleet te
          maken.
        </p>
        <PrimaryButton v-if="fullPage" class="flex items-center space-x-2 shadow-md pl-4" @click="wizard.back">
          <ChevronLeftIcon class="h-6" />
          Extra tracks kiezen
        </PrimaryButton>
      </div>
    </div>
  </div>
</template>
