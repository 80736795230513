<script setup>
import { ref } from 'vue'
import { config, hybrid } from '@dpgradio/creative'
import { useChartStore } from '../../stores/chart'
import ChevronRightIcon from '../../assets/icons/chevron_right.svg'
import CalendarIcon from '../../assets/icons/calendar.svg'
import Title from '../../components/elements/Title.vue'
import AddToCalendarModal from './AddToCalendarModal.vue'
import ShareButtons from '../../components/share/ShareButtons.vue'
import PrimaryButton from '../../components/elements/PrimaryButton.vue'

const chart = useChartStore()

const calendarModalOpen = ref(false)

const showAppLink = config('app.app_download_link') && !hybrid.isNativeApp()
</script>

<template>
  <div class="text-center space-y-6">
    <Title>Bedankt voor jouw lijst!</Title>
    <p>
      Luister binnenkort naar de <strong>{{ chart.name }}</strong> om er achter te komen of jouw favoriete tracks het
      tot de lijst hebben geschopt.
    </p>
    <PrimaryButton
      v-if="chart.startDate"
      class="mx-auto flex items-center space-x-2 shadow-md"
      @click="calendarModalOpen = true"
    >
      <CalendarIcon class="w-5" />
      <span>Plaats in mijn agenda</span>
    </PrimaryButton>
    <div v-if="showAppLink">
      <p class="!mb-2">Bekijk updates over de {{ chart.name }} in de {{ config('name') }} app:</p>
      <a :href="config('app.app_download_link')" target="_blank">
        <PrimaryButton class="flex items-center shadow-md mx-auto pr-4">
          Download de {{ config('name') }} app <ChevronRightIcon class="h-6" />
        </PrimaryButton>
      </a>
    </div>
    <ShareButtons />
    <AddToCalendarModal :open="calendarModalOpen" @close="calendarModalOpen = false" />
  </div>
</template>
