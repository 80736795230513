<script setup>
import { ref } from 'vue'
import Spinner from '../../assets/icons/spinner.svg'

const props = defineProps({
  share: { type: Function, required: true },
})

const loading = ref(false)

const shareWithState = async () => {
  loading.value = true
  await props.share()
  loading.value = false
}
</script>

<template>
  <button
    class="rounded-full px-2 py-2 text-primary hover:bg-primary hover:text-white"
    :disabled="loading"
    @click="shareWithState"
  >
    <Spinner v-if="loading" class="h-6" />
    <slot v-else />
  </button>
</template>
