<script setup>
import { useChartStore } from '../../../stores/chart'
import TrackOption from '../../tracks/TrackOption.vue'
import DynamicLoader from '../../elements/DynamicLoader.vue'
import CustomTrackModalButton from '../CustomTrackModalButton.vue'

const props = defineProps({
  letter: { type: String, required: true },
})

const chart = useChartStore()

const loadMoreTracks = async () => await chart.fetchTracksByLetter(props.letter, { nextPage: true })
</script>

<template>
  <div class="bg-element px-4 py-2">
    <DynamicLoader :load="loadMoreTracks">
      <div
        v-if="chart.tracksByLetter[letter]"
        ref="listElement"
        class="bg-element divide-y divide-solid divide-element-dark"
      >
        <TrackOption v-for="track in chart.tracksByLetter[letter]" :key="track.id" :track="track" />
        <div class="text-center py-12 space-y-2">
          <span>Staat je favoriete track er niet bij?</span>
          <CustomTrackModalButton />
        </div>
      </div>
    </DynamicLoader>
  </div>
</template>
