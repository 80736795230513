<script setup>
import { computed } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'red',
  },
  selected: {
    type: Boolean,
    default: false,
  },
})

const classes = computed(() => {
  if (props.type == 'red') {
    return 'bg-accent text-accent-c'
  }
  if (props.selected) {
    return 'bg-primary text-white'
  }
  return 'bg-element-light text-primary'
})
</script>

<template>
  <button
    class="rounded-full disabled:opacity-50 px-6 py-2 h-11 font-semibold font-sans text-base leading-none enabled:hover:bg-opacity-80"
    :class="classes"
  >
    <slot />
  </button>
</template>
