import params from './params'

const rootElement = document.getElementById('charts-voting')

export const listId = rootElement.dataset.listId || params.list_id

export const editionId = rootElement.dataset.editionId || params.edition_id

export const station = rootElement.dataset.station || params.station

export const isKioskMode = params.kiosk_mode
