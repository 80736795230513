import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { isKioskMode, station } from './settings'
import { configuration, setupAirbrake, privacy, authentication, dataLayer } from '@dpgradio/creative'
import { Notifier as AirbrakeNotifier } from '@airbrake/browser'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import './assets/css/index.scss'
import App from './App.vue'

window.createVueApp = async () => {
  try {
    await configuration.retrieveConfigForStation(station, 'charts-voting')
  } catch (error) {
    alert(`
      Could not load configuration.
      - Have you set up a configuration for this application in Dario (see [updateConfigSchema.js])? If you do not want a custom configuration, remove the application name from the [configuration.retrieveConfigForDetectedStation] call in [main.js].
      - Did you provide a station name? (e.g. through the URL parameter 'stationId') If you want to set the station name in code, replace the configuration retrieval call with [configuration.retrieveConfigForStation('<station-name>', 'application-name')] in [main.js].

      ${error}
    `)
  }

  authentication.initialize()

  const pinia = createPinia()
  const app = createApp(App)

  if (import.meta.env.PROD) {
    await setupAirbrake(
      AirbrakeNotifier,
      {
        projectId: 526856,
        projectKey: '871565cfae358dd0320422cec6e3d30d',
        version: import.meta.env.VITE_COMMIT_HASH,
      },
      app
    )
  }

  if (!isKioskMode) {
    pinia.use(piniaPluginPersistedstate)
    privacy.initialize()

    // when we load the chart as embed, we already have a dataLayer and have already pushed the virtual page view
    if (window.location.search.includes('edition_id')) {
      dataLayer.initialize()
      dataLayer.pushVirtualPageView()
    }
  }

  app.use(pinia)
  app.mount('#charts-voting')
}

window.createVueApp()
