<script setup>
import { config } from '@dpgradio/creative'
import { useUserStore } from '../stores/user'
import { useVotesStore } from '../stores/votes'
import Layout from '../components/Layout.vue'
import ContentBox from '../components/elements/ContentBox.vue'
import Title from '../components/elements/Title.vue'
import Header from '../components/elements/Header.vue'
import TextInput from '../components/elements/TextInput.vue'
import Switch from '../components/elements/Switch.vue'
import Confirm from '../components/Confirm.vue'

const user = useUserStore()
const votes = useVotesStore()

const updateCrmConsent = (val) => {
  if (val) {
    votes.meta.crmConsent = {
      touchpoint_url: window.location.href,
      question: user.crmConsentQuestion,
    }
  } else {
    votes.meta.crmConsent = false
  }
}
</script>

<template>
  <Layout>
    <Header>
      <Title>Vertel ons wie je bent</Title>
      <p>
        We horen graag hoe je heet zodat we kunnen vertellen wie er allemaal op de door jou gekozen tracks hebben
        gestemd.
      </p>
    </Header>
    <ContentBox class="!bg-element flex flex-col items-center">
      <div class="space-y-4 w-full xl:w-1/2 md:mb-4">
        <TextInput v-model="user.custom.firstName" label="Voornaam" />
        <TextInput v-model="user.custom.lastName" label="Achternaam" />
        <TextInput v-model="user.custom.phoneNumber" label="Telefoonnummer" />
        <TextInput v-model="user.custom.email" label="Emailadres" />
        <div class="flex space-x-2 text-left">
          <Switch v-model="votes.meta.phoneConsent" class="mt-2" />
          <div class="flex-1">
            <p class="!leading-snug !mb-1">{{ config('name') }} mag mij bellen in de uitzending.</p>
            <p class="!leading-none !text-xs text-gray-500">
              We spreken je graag over de nummers die jij gekozen hebt.
            </p>
          </div>
        </div>
        <div class="flex space-x-2 text-left">
          <Switch :modelValue="votes.meta.crmConsent" class="mt-2" @update:modelValue="updateCrmConsent" />
          <div class="flex-1">
            <p class="!leading-snug !mb-1">
              {{ user.crmConsentQuestion }}
            </p>
          </div>
        </div>
      </div>
      <Confirm class="text-center" />
    </ContentBox>
  </Layout>
</template>
