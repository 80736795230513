import { defineStore } from 'pinia'
import { authentication, api } from '@dpgradio/creative'
import crmApi from '../api'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      profile: undefined,
      newPhoneNumber: null,
      crmConsentQuestion: null,
      custom: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
      },
    }
  },
  actions: {
    setup() {
      if (authentication.isLoggedIn()) {
        this.fetchProfile()
      }
      authentication.onLogin(() => {
        this.fetchProfile()
      })
      this.fetchCrmConsentQuestion()
    },
    async fetchProfile() {
      this.profile = await api.members.me()
    },
    async savePhoneNumber() {
      if (this.newPhoneNumber) {
        await api.members.updateProfile({ mobile: this.newPhoneNumber })
      }
    },
    async fetchCrmConsentQuestion() {
      this.crmConsentQuestion = (await crmApi.getCrmQuestion()).question
    },
  },
})
