<script setup>
import { useChartStore } from '../stores/chart'
import { useWizardStore } from '../stores/wizard'
import { useVotesStore } from '../stores/votes'
import { useSearchStore } from '../stores/search'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import Layout from '../components/Layout.vue'
import Header from '../components/elements/Header.vue'
import Title from '../components/elements/Title.vue'
import ContentBox from '../components/elements/ContentBox.vue'
import TrackSearch from '../components/selection/search/TrackSearch.vue'
import TrackSearchResults from '../components/selection/search/TrackSearchResults.vue'
import SelectionCounter from '../components/selection/SelectionCounter.vue'
import SelectTracks from '../components/selection/SelectTracks.vue'
import SelectSuggestions from '../components/selection/SelectSuggestions.vue'
import SelectArtists from '../components/selection/SelectArtists.vue'
import TrackVotes from '../components/votes/TrackVotes.vue'

const wizard = useWizardStore()
const votes = useVotesStore()
const decideUserCanContinue = () => {
  if (votes.minVotesSatisfied) {
    wizard.enableNext()
  } else {
    wizard.disableNext()
  }
}
decideUserCanContinue()
votes.$subscribe(decideUserCanContinue)

const chart = useChartStore()
const search = useSearchStore()
</script>

<template>
  <Layout>
    <Header>
      <div>
        <Title>Selecteer je favorieten</Title>
        <span class="text-lg font-highlights">voor de {{ chart.name }}</span>
      </div>
      <div class="space-y-2">
        <span class="font-semibold text-highlight">Ken jij jouw favorieten al?</span>
        <TrackSearch />
      </div>
    </Header>

    <ContentBox v-if="search.query">
      <TrackSearchResults />
    </ContentBox>
    <div v-else>
      <TabGroup>
        <div class="bg-element">
          <TabList class="mx-4 md:mx-0 border-b border-solid border-gray-700 flex">
            <Tab v-for="name in ['Inspiratie', 'Artiesten', 'Tracks']" :key="name" v-slot="{ selected }" as="template">
              <button
                class="flex-1 py-2 px-0 font-highlights text-xl relative outline-none"
                :class="selected && 'text-primary font-semibold'"
              >
                {{ name }}
                <div
                  v-if="selected"
                  class="absolute -bottom-[1.5px] w-full border-solid border-b-[3px] border-primary"
                />
              </button>
            </Tab>
          </TabList>
        </div>
        <ContentBox class="md:px-0">
          <TabPanels>
            <TabPanel>
              <SelectSuggestions />
            </TabPanel>
            <TabPanel>
              <SelectArtists />
            </TabPanel>
            <TabPanel>
              <SelectTracks />
            </TabPanel>
          </TabPanels>
        </ContentBox>
      </TabGroup>
    </div>
    <SelectionCounter class="md:hidden" />
    <template #sidebar>
      <SelectionCounter class="mb-4" />
      <TrackVotes />
    </template>
  </Layout>
</template>
