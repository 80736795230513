import { defineStore } from 'pinia'
import dataLayer from '../dataLayer'

export const State = {
  Stopped: 0,
  Playing: 1,
}

export const usePlayerStore = defineStore('player', {
  state: () => {
    return {
      player: new Audio(),
      state: State.Stopped,
    }
  },
  getters: {
    isPlaying: (state) => (filename) => {
      return state.state == State.Playing && state.player.src == filename
    },
  },
  actions: {
    play(filename) {
      this.state = State.Stopped

      this.player.src = filename

      this.player.onplay = () => {
        this.state = State.Playing
      }
      this.player.onpause = () => {
        this.stop()
      }

      this.player.play()

      dataLayer.event('track_preview', 'start')
    },
    stop() {
      this.player.pause()
      this.player.currentTime = 0
      this.state = State.Stopped
    },
    togglePlay(filename) {
      if (this.isPlaying(filename)) {
        this.stop()
        dataLayer.event('track_preview', 'manual_stop')
      } else {
        this.play(filename)
      }
    },
  },
})
