<script setup>
import { ref } from 'vue'
import { v1 as uuidv1 } from 'uuid'
import { cdnImageUrl, config } from '@dpgradio/creative'
import { useVotesStore } from '../../stores/votes'
import Modal from '../elements/Modal.vue'
import Title from '../elements/Title.vue'
import TextInput from '../elements/TextInput.vue'
import SubtleLink from '../elements/SubtleLink.vue'
import PrimaryButton from '../elements/PrimaryButton.vue'

const props = defineProps({
  open: { type: Boolean, default: false },
  artist: { type: String, default: null },
})

const emit = defineEmits(['close'])

const votes = useVotesStore()

const artist = ref(props.artist)
const title = ref()

const add = (event) => {
  event.preventDefault()
  votes.vote({
    id: `custom-track-${uuidv1()}`,
    artist: artist.value,
    title: title.value,
    thumbnail: cdnImageUrl(config('app.fallback_image')),
  })
  close()
}

const clear = () => {
  artist.value = ''
  title.value = ''
}

const close = () => {
  emit('close')
  clear()
}
</script>

<template>
  <Modal :open="open" @close="close">
    <Title>Nieuwe track toevoegen</Title>
    <p>
      Sorry dat we je track niet in de zoeklijst hebben staan! Voeg 'm hieronder zelf toe aan je eigen lijst met de
      titel en artiest.
    </p>
    <form @submit="add">
      <div class="space-y-2 mb-4">
        <TextInput v-model="artist" label="Artiest" inputClass="!bg-element" />
        <TextInput v-model="title" label="Titel" inputClass="!bg-element" />
      </div>

      <div class="flex items-end justify-between">
        <SubtleLink @click="close">Annuleer</SubtleLink>
        <PrimaryButton>Toevoegen</PrimaryButton>
      </div>
    </form>
  </Modal>
</template>
