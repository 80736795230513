<script setup>
import Layout from '../components/Layout.vue'
import Header from '../components/elements/Header.vue'
import ContentBox from '../components/elements/ContentBox.vue'
import TrackVotes from '../components/votes/TrackVotes.vue'
import Title from '../components/elements/Title.vue'
import { useChartStore } from '../stores/chart'
import { useVotesStore } from '../stores/votes'
import { useWizardStore } from '../stores/wizard'

const wizard = useWizardStore()
const chart = useChartStore()
const votes = useVotesStore()

const decideUserCanContinue = () => {
  if (votes.minVotesSatisfied) {
    wizard.enableNext()
  } else {
    wizard.disableNext()
  }
}
decideUserCanContinue()
votes.$subscribe(decideUserCanContinue)
</script>

<template>
  <Layout>
    <Header>
      <Title>Stem op jouw {{ chart.name }}</Title>
      <p>
        Dubbelcheck je lijst nog even! Staat je top {{ chart.constraints.minVotes }} goed? Mis je tracks? Ga dan een
        stap terug. Helemaal tevreden? Druk dan op volgende!
      </p>
    </Header>
    <ContentBox>
      <TrackVotes :fullPage="true" />
    </ContentBox>
  </Layout>
</template>
