<script setup>
import { ref, watch } from 'vue'
import LoadingIndicator from './LoadingIndicator.vue'

const props = defineProps({
  load: { type: Function, required: true },
})

const listElement = ref(null)

const state = ref('idle')

const loadMore = async () => {
  if (state.value == 'idle') {
    state.value = 'loading'
    const { nextAvailable } = await props.load()
    if (!nextAvailable) {
      state.value = 'end'
    } else {
      state.value = 'idle'
    }
  }
}

loadMore()

watch(listElement, () => {
  ;[window, document.querySelector('.mobile-dialog'), document.querySelector('.scroll-box')].forEach((element) => {
    element?.addEventListener('scroll', async () => {
      if (!listElement.value) {
        return
      }
      if (listElement.value.getBoundingClientRect().bottom < window.innerHeight) {
        loadMore()
      }
    })
  })
})
</script>

<template>
  <div ref="listElement">
    <slot />
  </div>
  <LoadingIndicator v-if="state == 'loading'" />
</template>
