<script setup>
import TrackPosition from './TrackPosition.vue'
import Track from '../tracks/Track.vue'
import { useVotesStore } from '../../stores/votes'
import ChevronUpIcon from '../../assets/icons/chevron_up.svg'
import ChevronDownIcon from '../../assets/icons/chevron_down.svg'
import TrashIcon from '../../assets/icons/trash.svg'
import DragIcon from '../../assets/icons/drag.svg'
import { computed } from 'vue'

const props = defineProps({
  position: { type: Number, required: true },
  track: { type: Object, required: true },
  showPosition: { type: Boolean, default: false },
})

const votes = useVotesStore()

const index = computed(() => props.position - 1)
const moveUp = () => votes.move(props.track, index.value - 1)
const moveDown = () => votes.move(props.track, index.value + 1)
</script>

<template>
  <div class="flex relative">
    <div class="handle flex flex-col justify-center items-center opacity-50 px-2">
      <DragIcon class="h-6" />
    </div>
    <TrackPosition v-if="showPosition" :position="position" />
    <Track :track="track" class="flex-1 track">
      <div class="flex items-center">
        <button
          class="h-8 w-8 p-1 rounded-lg text-inherit hover:bg-white hover:text-primary"
          @click="votes.remove(track)"
        >
          <TrashIcon class="h-5 mx-auto" />
        </button>
        <div class="h-full flex flex-col justify-center items-center -my-2 mr-2">
          <button
            v-show="position > 1"
            class="rounded-lg p-1 text-inherit hover:bg-white hover:text-primary"
            @click="moveUp"
          >
            <ChevronUpIcon class="w-6 h-6" />
          </button>
          <button
            v-show="position < votes.count"
            class="rounded-lg p-1 text-inherit hover:bg-white hover:text-primary -mt-2"
            @click="moveDown"
          >
            <ChevronDownIcon class="w-6 h-6" />
          </button>
        </div>
      </div>
    </Track>
  </div>
</template>
