<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import ChevronUpIcon from '../../assets/icons/chevron_up.svg'
import ChevronDownIcon from '../../assets/icons/chevron_down.svg'
import { useChartStore } from '../../stores/chart'
import TracksByLetterList from './lists/TracksByLetterList.vue'
import ScrollBox from '../elements/ScrollBox.vue'

const chart = useChartStore()
</script>

<template>
  <ScrollBox class="space-y-2">
    <div v-for="letter in Object.keys(chart.tracksByLetter)" :key="letter" class="bg-element">
      <Disclosure v-slot="{ open }">
        <DisclosureButton
          class="bg-element bg-opacity-50 backdrop-blur hover:bg-element-dark text-primary font-highlights text-lg font-semibold p-4 flex items-center justify-between w-full z-50"
          :class="open && 'sticky top-0 shadow-md'"
        >
          {{ letter }}
          <ChevronDownIcon v-if="!open" class="h-6" />
          <ChevronUpIcon v-else class="h-6" />
        </DisclosureButton>
        <DisclosurePanel>
          <TracksByLetterList :letter="letter" />
        </DisclosurePanel>
      </Disclosure>
    </div>
  </ScrollBox>
</template>
